.buttonComponent {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 8px rgb(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 8px rgb(0, 0, 0, 0.3);
  box-shadow: inset 0 0 8px rgb(0, 0, 0, 0.3);
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
}

.imageButton {
  position: absolute;
  cursor: pointer;
}

.buttonComponent.submit {
  background: linear-gradient(to top, #1297ce, #37c8e8) padding-box,
    linear-gradient(to right, #e6fffa, #d3deff, #bbdaff) border-box;

  -webkit-box-shadow: inset 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
  border: 4px solid transparent;
}

.buttonComponent.normal {
  background: linear-gradient(to top, #2f87d2, #58b6f2) padding-box,
    linear-gradient(to right, #e6fffa, #d3deff, #bbdaff) border-box;
  -webkit-box-shadow: inset 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
  border: 4px solid transparent;
}

.buttonComponent.submit.hovered {
  background: linear-gradient(to bottom, #1297ce, #37c8e8) padding-box,
    linear-gradient(to right, #e6fffa, #d3deff, #bbdaff) border-box;
  -webkit-box-shadow: inset 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
  border: 4px solid transparent;
}

.buttonComponent.normal.hovered {
  background: linear-gradient(to bottom, #2f87d2, #58b6f2) padding-box,
    linear-gradient(to right, #e6fffa, #d3deff, #bbdaff) border-box;
  -webkit-box-shadow: inset 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
  border: 4px solid transparent;
}

.submit .buttonText {
  font-size: 40px;
  color: white;
  background: linear-gradient(to top, #ffffff, #d7fffe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Futura_Std_Bold";
  text-shadow: 0px 15px 50px rgb(0, 0, 0, 0.25);
  /* -webkit-text-stroke-width: 3.5px;
  -webkit-text-stroke-color: rgba(13, 94, 113); */
  filter: drop-shadow(0px 0px 4px #2f5ca3);
}

.normal .buttonText {
  font-size: 35px;
  color: white;
  font-family: "Futura_Std_Bold";
}

.submit.disabled {
  pointer-events: none;
  background: linear-gradient(to bottom, rgb(18, 151, 206, 0.5), rgb(55, 200, 232, 0.5)) padding-box,
    linear-gradient(to right, #e6fffa, #d3deff, #bbdaff) border-box;
}

.submit.disabled .buttonText {
  font-size: 40px;
  color: rgb(195, 195, 195);
  background: linear-gradient(to top, #ffffff, #d7fffe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Futura_Std_Bold";
  /* text-shadow: 0px 15px 50px rgb(0, 0, 0, 0.5); */
  /* -webkit-text-stroke-width: 3.5px;
  -webkit-text-stroke-color: rgba(13, 94, 113, 0.5); */
  filter: drop-shadow(0px 0px 4px #2f5ba300);
}

.disabled {
  pointer-events: none;
}
