.Timer {
  font-family: Futura_Std_Bold;
  color: #013442;
  font-size: 37px;
  text-align: left;
  position: absolute;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
}
