.MainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-image: url("../../public/assets/images/main_bg.png");
}

.loader {
  width: 175px;
  height: 175px;
  background-image: url("../../public/assets/images/preloader.png");
  animation: preloadAnim 2s steps(96) infinite;
}

@keyframes preloadAnim {
  100% {
    background-position: -16800px;
  }
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../fonts/Roboto_Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto_Bold";
  src: url("../fonts/Roboto_Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto_Regular";
  src: url("../fonts/Roboto_Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura_Std_Bold";
  src: url("../fonts/Futura_Std_Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura_Std_Medium";
  src: url("../fonts/Futura_Std_Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
