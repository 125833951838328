.LeaderBoardEntry{
    display: flex;
    justify-content: center;
    text-align: center;
    width: 600px;
    height: 54px;
    margin-top: 5px;
    border-radius: 35px;
    align-items: center;
}

.LeaderBoardEntry .rankEntry {
    width: 20%;
    font-family: 'Roboto_Bold';
    font-size: 30px;
    color: #212121;
}

.LeaderBoardEntry .playerNameEntry {
    width: 60%;
    font-family: 'Roboto_Regular';
    font-size: 30px;
    color: #212121;
}

.LeaderBoardEntry .scoreEntry {
    width: 20%;
    font-family: 'Roboto_Bold';
    font-size: 27px;
    color: #313e82;
}