.PauseMenu {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 28px;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.PauseMenu .buttonComponent {
  position: relative;
  margin: 15px 0px;
}

.PauseMenu .buttonComponent .buttonIcon {
  margin-right: 15px;
}
