.Result {
  width: 720px;
  height: 1026px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 25px;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
}

.Result .heading {
  margin-top: 50px;
}

.Result .scoreHolderContainer {
  display: flex;
  position: relative;
  top: 35px;
}

.Result .scoreHolder {
  height: 160px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Result .firstBox {
  margin-left: -5px;
}

.Result .secondBox {
  margin-left: 30px;
}

.Result .starImage {
  width: 139px;
  height: 63px;
  position: absolute;
  top: -19px;
}

.Result .scoreLabel {
  font-family: "Roboto_Bold";
  font-size: 32px;
  color: #09464f;
  position: absolute;
  top: 50px;
}

.Result .scoreNumber {
  font-family: "Roboto_Bold";
  font-size: 48px;
  color: #09464f;
  background: linear-gradient(to top, #ffd1fc, #fad0c6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: linear-gradient(to top, #082859, #135089);
  position: absolute;
  top: 90px;
}

.Result .statBox {
  height: 443px;
  width: 636px;
  background-color: #9db4c8;
  top: 60px;
  position: relative;
  border-radius: 25px;
}

.Result .contentContainer {
  padding: 20px 45px;
}

.Result .statRow {
  display: flex;
  align-items: center;
  height: 85px;
  justify-content: space-between;
}

.Result .statBox .text {
  font-size: 30px;
  font-family: "Roboto_Bold";
  color: #203d55;
}

.Result .statBox .number {
  font-size: 30px;
  font-family: "Roboto_Bold";
  color: #ffffff;
}

.Result .statBox .greyBox {
  background-color: rgb(97, 131, 162);
  width: 152px;
  border-radius: 8px;
  height: 47px;
  display: flex;
  align-items: center;
  color: #ffffff;
  justify-content: center;
  font-family: "Roboto_Bold";
  font-size: 30px;
}

hr {
  border-color: #ffffff;
}

.Result .buttonHolder {
  width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
}

.Result .buttonHolder .buttonHolderTop {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.Result .buttonHolder .buttonHolderBottom {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.Result .resultButton {
  position: relative;
  margin: 5px;
}
