.feedbackMainContainer {
  width: 657px;
  height: 890px;
  background-color: white;
  position: absolute;
  margin: auto;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -20px;
  bottom: 0;
  right: 0;
  border-radius: 50px;
  background-color: rgba(115, 144, 161, 0.5);
}

.iconWrapper {
  width: 625px;
  height: 208px;
  margin-top: 15px;
  border-radius: 35px;
  font-size: 35px;
  color: #09464f;
  background-color: rgb(168, 207, 220);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.feedbackContainer {
  height: 600px;
  padding-top: 20px;
  width: 600px;
  background-color: rgb(245, 247, 248);
  border-radius: 35px;
  margin-top: 10px;
  font-size: 35px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 10px solid rgb(171, 211, 223);
}

.feedbackMainContainer .iconText {
  font-family: "Roboto_Bold";
}

.feedbackContainer li{
  list-style-type:'- ';
}