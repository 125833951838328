.FurtherInfo {
  width: 720px;
  height: 1026px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 25px;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
}

.FurtherInfo .furtherInfoHeading {
  margin-top: 50px;
}

.FurtherInfo .subHeading {
  font-family: "Roboto_Bold";
  margin-bottom: 40px;
}

.FurtherInfo .fontBold {
  font-family: "Roboto_Bold";
  display: inline;
}

.FurtherInfo .infoContainer {
  width: 560px;
  height: 703px;
  margin-top: 20px;
  padding: 25px;
  border: 5px solid #aed7e3;
  background-color: rgb(198, 215, 225, 0.7);
  border-radius: 35px;
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-family: "Roboto_Regular";
  color: #203d55;
}

.FurtherInfo li {
  margin-bottom: 15px;
}
