/* ::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient( #a6a8ab,#ffffff);
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background: #40466f;
    border: 3px solid transparent;
    border-radius: 10px;
    background-clip: content-box; 
}

@supports (scrollbar-color: red blue) {
    *
    {
      scrollbar-color: linear-gradient( #a6a8ab,#ffffff) #40466f;
      scrollbar-width: auto;
    }
}  */

.track-vertical {
  visibility: visible;
  position: absolute;
  background: #40466f;
  width: 6px;
  right: 5px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
}

.thumb-vertical {
  position: relative;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(222, 226, 231, 1));
  display: block;
  left: -3px;
  width: 12px !important;
  box-shadow: 5px 0 5px -5px rgba(31, 73, 125, 0.8), -5px 0 5px -5px rgba(31, 73, 125, 0.8);
  cursor: pointer;
  border-radius: inherit;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}

.view {
  position: absolute;
  inset: 0px;
  overflow: scroll;
  margin-right: -17px;
  margin-bottom: -17px;
  padding-right: 30px;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
}

.thumbLine {
  background: #cecece;
  position: relative;
  left: 2px;
  width: 8px;
  height: 2px;
}

#textContainer {
  padding: 5px !important;
  display: flex;
  align-items: center;
}

.mCSB_scrollTools .mCSB_draggerRail {
  opacity: 1 !important;
  width: 6px !important;
  background-color: #40466f !important;
  border-radius: 3px !important;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: linear-gradient(180deg, #fff, #dee2e7);
  right: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: inherit;
  border-radius: 10px;
  box-shadow: 5px 0 5px -5px rgba(31, 73, 125, 0.8), -5px 0 5px -5px rgba(31, 73, 125, 0.8);
  cursor: pointer;
  display: block;
  position: relative;
  width: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar .mCSB_dragger_innerLines {
  width: 8px;
  height: 2px;
  background-color: #cecece;
  margin: 1.5px 0px;
}

.mCSB_scrollTools .mCSB_draggerContainer {
  right: 5px !important;
  background: #40466f;
  border-radius: 10px;
  width: 6px;
}

.mCSB_scrollTools {
  opacity: 1 !important;
}
