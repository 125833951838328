.ContentArea {
  width: 720px;
  height: 1080px;
  aspect-ratio: 0.6666;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContentArea .contentWrapper {
  width: 100%;
  height: 1026px;
  aspect-ratio: 0.6666;
  position: relative;
}

.ContentArea .fetchingData {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
