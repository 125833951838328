.Game {
  position: relative;
  width: 100%;
  height: 100%;
}

.Game .gameWrapper {
  width: 722px;
  height: 949px;
  position: relative;
  margin: auto;
}

.Game .gameWrapper .mapCliper {
  position: relative;
  width: 100%;
  height: 98%;
  top: 10px;
  overflow: hidden;
}

.Game .gameWrapper .map {
  position: relative;
  width: 100%;
  height: 95%;
  transition: bottom 0.5s;
}

.Game .scoreWrapper {
  width: 100%;
  height: 70px;
  margin-bottom: 10px;
}

.Game .scoreWrapper .timer {
  position: relative;
  width: 127px;
  height: 68px;
  margin: 0 50px;
}

.Game .scoreWrapper .timer .timerBg {
  position: absolute;
}

.Game .scoreWrapper .score {
  width: 247px;
  height: 68px;
  margin: 0 50px;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
  position: relative;
}

.Game .scoreWrapper .score .scoreSubText {
  font-family: Futura_Std_Medium;
  font-size: 37px;
  color: #013442;
  width: 50%;
}

.Game .scoreWrapper .score .scoreText {
  font-family: Futura_Std_Bold;
  font-size: 37px;
  color: #013442;
  width: 50%;
}

.Game .scoreWrapper .score .scoreBg {
  position: absolute;
  z-index: -1;
}

.Game .scoreWrapper .pause {
  width: 127px;
  height: 68px;
  margin: 0 50px;
}

.flexed {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Game .playButtonHolder {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
}

.Game .mapCliper .finishLineImg {
  position: absolute;
  opacity: 0;
}
