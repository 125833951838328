.submitScore {
  width: 720px;
  height: 1026px;
  display: flex;
  position: absolute;
  top: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 25px;
}

.submitScore .formContainer {
  width: 604px;
  position: absolute;
  height: 334px;
  top: 358px;
  flex-direction: column;
  border: 5px solid #aed7e3;
  border-radius: 30px;
  display: flex;
  background-color: rgb(106, 155, 196);
  justify-content: flex-start;
  align-items: center;
}

.submitScore .closeBtn {
  height: 49px;
  width: 49px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  border: none;
  top: 30%;
  right: 63px;
}

.submitScore .label {
  font-size: 35px;
  font-family: "Futura_Std_Bold";
  color: #1a4a73;
  position: absolute;
  top: 35%;
}

.submitScore .nameLabel {
  margin-bottom: 15px;
}

.submitScore .infoHolder {
  width: 450px;
  height: 120px;
  background-color: #5a89b1;
  position: absolute;
  top: 47%;
  border-radius: 10px;
}

.submitScore .inputBox {
  font-size: 30px;
  position: absolute;
  border-radius: 10px;
  top: 40%;
  font-family: "Roboto_Regular";
  color: #1a4a73;
  -webkit-box-shadow: inset 0px 0px 8px 5px #7ea5be;
  -moz-box-shadow: inset 0px 0px 8px 5px #7ea5be;
  box-shadow: inset 0px 0px 8px 5px #7ea5be;
  background-color: rgb(165, 204, 230);
  border: none;
  width: 450px;
  height: 57px;
  text-align: center;
}

.submitScore input:focus {
  outline: none;
}

.submitScore .infoHolder {
  display: flex;
  font-family: "Roboto_Regular";
  font-size: 24px;
  color: #143a5a;
}

.submitScore .infoIcon {
  height: 25px;
  width: 25px;
  padding: 8px 0px 0px 7px;
}

.submitScore .infoText {
  display: flex;
  align-items: center;
  justify-content: center;
}
