.HexagonTile {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  font-size: 50px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.activeTile {
  pointer-events: all;
}

.disableTile {
  pointer-events: none;
}

.textIndex {
  position: absolute;
}

.stateImage {
  width: 100%;
  height: 100%;
}

.spriteAnim {
  animation: arrowAnim 2s steps(48) infinite;
}

@keyframes arrowAnim {
  100% {
    background-position: -7008px;
  }
}

.bonusAnimHolder {
  font-family: "Futura_Std_Bold";
  position: absolute;
  font-size: 10px;
  -webkit-text-stroke: 3px #3e3e61;
  -webkit-text-fill-color: white;
  opacity: 0;
  transition: opacity 200ms ease;
}

.fadeIn {
  opacity: 1;
}
