.Menu {
  width: 720px;
  height: 1026px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 25px;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
}

.Menu .mainHeading {
  margin-top: 60px;
  width: 494px;
  height: auto;
}

.Menu .blueBox {
  width: 627px;
  margin-top: 40px;
  height: 613px;
  border: 5px solid #aed7e3;
  background-color: rgb(198, 215, 225, 0.7);
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
