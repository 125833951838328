.finishPage{
    width: 720px;
  height: 1026px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 25px;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
}

.finishPage .textContainer{
    width: 560px;
    height: 780px;
    margin-top: 50px;
    padding: 25px;
    border: 5px solid #aed7e3;
    background-color: rgb(198, 215, 225, 0.7);
    border-radius: 35px;
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-family: "Roboto_Regular";
    color: #203d55;
}

.finishPage .subHeading {
    font-family: "Roboto_Bold";
}

.finishPage .firstHeading {
    margin-bottom: 20px;
}

.finishPage ul{
    font-size: 27px;
}

.finishPage .secondUl{
    margin-top: 0;
}

.finishPage .secondPara{
    display: inline;
}

.finishPage .fontBold{
    font-family: "Roboto_Bold";
    display: inline;
}