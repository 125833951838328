.Instruction {
  width: 720px;
  height: 1026px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 25px;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
}

.Instruction .instructionHeading {
  margin-top: 50px;
}

.Instruction .subHeading {
  font-family: "Roboto_Bold";
}

.Instruction .subHeading.secondHeading {
  margin-top: 50px;
}

.Instruction .subHeading.thirdHeading {
  margin-top: 40px;
}

.Instruction .textContainer {
  width: 560px;
  height: 703px;
  margin-top: 15px;
  padding: 25px;
  border: 5px solid #aed7e3;
  background-color: rgb(198, 215, 225, 0.7);
  border-radius: 35px;
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-family: "Roboto_Regular";
  color: #203d55;
}

.Instruction .imageHolder{
  display: inline;
}

.Instruction .iconImage{
  width: 88px;
  height: 77px;
}

.Instruction .imageParentDiv{
  display: flex;
  align-items: center;
}

.Instruction .textDiv{
  width: 80%;
}