.Leaderboard {
  width: 720px;
  height: 1026px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 25px;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
}

.Leaderboard .LeaderboardHeading {
  margin-top: 50px;
}

.Leaderboard .leaderBoardBox {
  width: 580px;
  height: 660px;
  margin-top: 20px;
  padding: 50px 50px 20px 35px;
  border: 5px solid #aed7e3;
  background-color: rgb(181, 204, 217, 0.7);
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #212121;
}

.Leaderboard .titleContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 600px;
  font-family: "Roboto_Bold";
  font-size: 30px;
  color: #212121;
}

.Leaderboard .entryContainer {
  margin-top: 5px;
  width: 600px;
}

.Leaderboard .titleContainer .rank {
  width: 20%;
}

.Leaderboard .titleContainer .playerName {
  width: 60%;
}

.Leaderboard .titleContainer .score {
  width: 20%;
}
