.Intro {
  width: 720px;
  height: 1026px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
  border-radius: 25px;
}

.Intro .logoHolder {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 200px;
}

.Intro .mainHeading {
  margin-top: 180px;
}
