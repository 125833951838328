.RadioWithLabel {
  display: flex;
  margin-bottom: 5px;
}

.radioButtonContainer {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outerCircle {
  height: 35px;
  width: 35px;
  border: 2px solid #0c5762;
  border-radius: 50%;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.innerCircle {
  height: 21px;
  border-radius: 50%;
  width: 21px;
  pointer-events: none;
  cursor: default;
}

.innerCircle.selected {
  background-color: #0c5762;
}

.optionTextContainer {
  width: 80%;
  font-size: 30px;
  cursor: pointer;
}
.optionTextContainer .view {
  display: flex;
  align-items: center;
}
